import {
  Button,
  ButtonColor,
  ButtonWidth,
  IconLinkExternal,
  IconShare,
  IconWarning,
  isDateAfter,
  ProfileImage,
} from '@leland-dev/leland-ui-library';
import { useRouter } from 'next/router';
import React, {
  type ComponentPropsWithoutRef,
  type PropsWithChildren,
  type ReactElement,
  useEffect,
} from 'react';

import { CoachStatus } from '../../__generated-gql-types__/globalTypes';
import { useAuth } from '../../context/AuthContext';
import {
  FeatureInteraction,
  useFeatureInteractions,
} from '../../context/FeatureInteractionsContext';
import { APPLICANT_SITE_URL } from '../../utils/constants';
import { logException } from '../../utils/exception';
import useMediaQuery, { Breakpoints } from '../../utils/hooks/useMediaQuery';
import { useOnce } from '../../utils/hooks/useOnce';
import { getCoachProfileUrl, redirectToLogin } from '../../utils/url';
import LoadingIndicator from '../common/LoadingIndicator';
import { useSocialShareModal } from '../common/SocialShareModal';

import Page from './Page';
import PageContent from './PageContent';

const SOCIAL_SHARE_MODAL_BEGIN_DATE = new Date('2024-04-09T12:00:00.000Z');

type TitleProps =
  | {
      titleClassName?: string;
      hideTitleRow?: never;
    }
  | {
      titleClassName?: never;
      hideTitleRow: true;
    };
export type AuthedPageBaseProps = TitleProps &
  Pick<
    ComponentPropsWithoutRef<typeof PageContent>,
    'hideInternalNavigation' | 'secondaryNavbar' | 'navLinks'
  > &
  PropsWithChildren<{
    title: string;
    pageTitle?: string;
    pageDescription?: string | ReactElement;
    contentClassName?: string;
    titleRowClassName?: string;
    showProfilePicture?: boolean;
    showProfileLink?: boolean;
    stickyNav?: boolean;
    enforceOps?: boolean;
    rightMainPageHeaderContent?: ReactElement;
    childrenContainerClassName?: string;
    preventMaxWidth?: boolean;
    aboveTitleContent?: ReactElement;
    belowTitleContent?: Nullable<ReactElement>;
  }>;

const AuthedPageBase: React.FC<AuthedPageBaseProps> = ({
  title,
  pageTitle = title,
  hideTitleRow,
  titleClassName,
  pageDescription,
  contentClassName,
  titleRowClassName,
  showProfilePicture,
  showProfileLink,
  enforceOps,
  rightMainPageHeaderContent,
  childrenContainerClassName,
  preventMaxWidth,
  aboveTitleContent,
  belowTitleContent,
  hideInternalNavigation,
  secondaryNavbar,
  navLinks,
  children,
}) => {
  const { currentUser, isLoadingUser, subscribeToAuthChanges } = useAuth();
  const router = useRouter();
  const isMd = useMediaQuery(Breakpoints.MD);

  const { setOpen: setSocialShareModalOpen, Modal: SocialShareModal } =
    useSocialShareModal();

  const { isFeatureInteracted, logFeatureInteraction } =
    useFeatureInteractions();
  const isSocialShareFeatureInteracted = isFeatureInteracted(
    FeatureInteraction.IM_LIVE_SOCIAL_SHARE_MODAL,
  );

  useOnce(
    () => setTimeout(() => setSocialShareModalOpen(true), 2000),
    currentUser?.coach?.status === CoachStatus.LIVE &&
      isDateAfter(
        currentUser.coach.liveAt ?? SOCIAL_SHARE_MODAL_BEGIN_DATE,
        SOCIAL_SHARE_MODAL_BEGIN_DATE,
      ) &&
      !isSocialShareFeatureInteracted,
  );

  useEffect(subscribeToAuthChanges, [subscribeToAuthChanges]);

  useEffect(() => {
    if (isLoadingUser) {
      return;
    }

    if (currentUser == null) {
      void redirectToLogin(router);
    } else if (currentUser.coach == null && currentUser.applicant != null) {
      void router.push(APPLICANT_SITE_URL);
    } else if (enforceOps && !currentUser.isOps) {
      void router.push('/');
    }
  }, [currentUser, enforceOps, isLoadingUser, router]);

  if (isLoadingUser) {
    return (
      <Page title={`Loading ${pageTitle}`}>
        <PageContent
          hideInternalNavigation={hideInternalNavigation}
          navLinks={navLinks}
        >
          <LoadingIndicator
            className="h-screen-below-navbar"
            iconClassName="w-20 h-20"
            text="Loading user details..."
          />
        </PageContent>
      </Page>
    );
  }

  if (
    currentUser == null ||
    (currentUser.coach == null && currentUser.applicant != null) ||
    (enforceOps && !currentUser.isOps)
  ) {
    return null;
  }

  if (currentUser.coach == null) {
    // User is neither applicant nor coach
    logException('User is neither applicant nor coach');
    return (
      <Page title={pageTitle}>
        <PageContent
          hideInternalNavigation={hideInternalNavigation}
          navLinks={navLinks}
        >
          <div className="flex size-full flex-col items-center justify-center">
            <IconWarning className="mx-auto size-5 text-leland-red" />
            <h3 className="mb-4 mt-3 text-2xl font-normal">
              Something went wrong
            </h3>
            <p className="mt-4 text-leland-gray-light">
              We&rsquo;re sorry! Please try again.
            </p>
          </div>
        </PageContent>
      </Page>
    );
  }

  const heightClassName = secondaryNavbar
    ? 'h-screen-below-navbar-and-secondary-navbar'
    : 'h-screen-below-navbar';

  return (
    <Page title={pageTitle}>
      <PageContent
        hideInternalNavigation={hideInternalNavigation}
        secondaryNavbar={secondaryNavbar}
        navLinks={navLinks}
      >
        <div className={`flex w-full ${heightClassName}`}>
          <div
            id="page-content"
            className={`h-screen-below-navbar w-full max-w-[100vw] overflow-y-auto sm:max-w-[unset] ${heightClassName} ${contentClassName}`}
          >
            <div
              className={`p-4 lg:p-8 lg:py-10 ${
                !preventMaxWidth ? 'mx-auto max-w-page' : ''
              } ${childrenContainerClassName}`}
            >
              {aboveTitleContent}
              {hideTitleRow ? null : (
                <div
                  className={`mb-10 flex flex-col flex-wrap items-start justify-center gap-6 bg-white lg:mb-12 lg:flex-row lg:items-center lg:justify-between lg:gap-3 ${titleRowClassName}`}
                >
                  <div className="flex w-full items-center">
                    <div className="flex w-full flex-col items-start justify-center">
                      <div className="flex w-full flex-col items-center justify-between gap-6 sm:flex-row">
                        {showProfilePicture ? (
                          <ProfileImage
                            className="mr-4 size-10"
                            pictureLink={currentUser.pictureLink}
                            firstName={currentUser.firstName}
                            lastName={currentUser.lastName}
                          />
                        ) : null}
                        <h1
                          className={`w-full grow text-4xl font-medium text-leland-gray-dark ${
                            titleClassName ?? ''
                          }`}
                        >
                          {title}
                        </h1>
                        {(showProfileLink && currentUser?.coach?.id != null) ||
                        rightMainPageHeaderContent ? (
                          <div className="flex w-full flex-col items-start justify-start space-y-2 md:w-fit md:flex-row md:items-center md:space-x-2 md:space-y-0">
                            {showProfileLink &&
                            currentUser?.coach?.id != null ? (
                              <>
                                <a
                                  className="w-full md:w-fit"
                                  href={getCoachProfileUrl(
                                    currentUser.coach.slug,
                                    null,
                                  )}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <Button
                                    label="View my profile"
                                    RightIcon={
                                      isMd &&
                                      currentUser.coach.status ===
                                        CoachStatus.LIVE
                                        ? undefined
                                        : IconLinkExternal
                                    }
                                    buttonColor={ButtonColor.WHITE}
                                    rounded={isMd}
                                    width={ButtonWidth.FULL_MOBILE}
                                  />
                                </a>
                                {currentUser.coach.status ===
                                CoachStatus.LIVE ? (
                                  <Button
                                    label="Share my profile"
                                    showLabel={!isMd}
                                    RightIcon={IconShare}
                                    rounded={isMd}
                                    onClick={() =>
                                      setSocialShareModalOpen(true)
                                    }
                                    width={ButtonWidth.FULL_MOBILE}
                                  />
                                ) : null}
                              </>
                            ) : null}
                            {rightMainPageHeaderContent}
                          </div>
                        ) : null}
                      </div>
                      {pageDescription ? (
                        <p className="mt-6 text-lg leading-6 text-leland-gray-light">
                          {pageDescription}
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
              )}
              {belowTitleContent}
              {children}
            </div>
          </div>
        </div>
      </PageContent>

      <SocialShareModal
        onClose={
          isSocialShareFeatureInteracted
            ? undefined
            : () =>
                logFeatureInteraction(
                  FeatureInteraction.IM_LIVE_SOCIAL_SHARE_MODAL,
                )
        }
      />
    </Page>
  );
};

export default AuthedPageBase;
